import React, { useState, useEffect } from "react";
import Header from "../header"
import Footer from "../footer";
import { Helmet } from "react-helmet";
import FooterMobile from "../footerMobile";
/*import "../../styles/global.css"*/

/////////// Layout Home ////////////////////

export default function Layout({selectedMenuItem, children, parameters, marca }) {
  /* console.log("Layout Home"); */


  return (
    <>
        <Header selectedMenuItem={selectedMenuItem} parameters={parameters} marca={marca}/>
        
        <div>
          {children}
            <seez-sdk-seezar-modal></seez-sdk-seezar-modal>
        </div>
        <FooterMobile/>
        <Footer marca={marca}/>
    </>
  )
}